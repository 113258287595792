import { Component } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { ToastService } from 'src/app/shared/services/toast/toast.service';
import { getControlErrorMessage } from 'src/utils';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  loading: boolean = false;
  showPassword: boolean = false;
  errorMessage = getControlErrorMessage;

  // Creamos el formulario
  loginForm: FormGroup = this.fb.group({
    email: ['', [Validators.email, Validators.required]],
    password: ['', [Validators.required]],
  });

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private auth: AuthService,
    private toast: ToastService
  ) { }

  // Getter email
  get email(): AbstractControl | null {
    return this.loginForm.get('email');
  }

  // Getter password
  get password(): AbstractControl | null {
    return this.loginForm.get('password');
  }

  /**
   * Método para enviar el formulario
   */
  onSubmit(): void {
    if (this.loginForm.valid) {
      this.loading = true;

      this.auth
        .signIn(this.loginForm.value)
        .then((user) => {
          this.loading = false;

          switch (user.challengeName) {
            /**
             * Caso cuando es requerido el cambio de contraseña desde inicio de sesión
             */
            case 'NEW_PASSWORD_REQUIRED':
              this.auth.cognitoUser$.next(user);
              this.router.navigate(['new-password']);
              break;

            default:
              this.router.navigate(['bracelets']);
              break;
          }
        })
        .catch((err) => {
          this.loading = false;
          this.toast.error(err.message);
        });
    }
  }
}
