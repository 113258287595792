/**
 * Mensajes de error de formularios de validación
 */
export const FORM_ERROR_MESSAGES: { [key: string]: string } = {
  required: 'Este campo es requerido',
  email: 'El correo que ingresaste no es válido',
  mustMatch: 'Las contraseñas no coinciden',
  pattern:
    'La longitud del código no es válida ó contiene algún valor no numérico',
};
