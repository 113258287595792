import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { take } from 'rxjs';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { PasswordMatchValidator } from 'src/app/core/validators/password-match.validator';
import { ToastService } from 'src/app/shared/services/toast/toast.service';
import { getControlErrorMessage } from 'src/utils';

@Component({
  selector: 'app-new-password',
  templateUrl: './new-password.component.html',
  styleUrls: ['./new-password.component.scss'],
})
export class NewPasswordComponent implements OnInit {
  loading: boolean = false;
  showPassword: boolean = false;
  showConfirmPassword: boolean = false;
  errorMessage = getControlErrorMessage;
  cognitoUser: any;

  // Creamos el formulario
  newPassForm: FormGroup = this.fb.group(
    {
      password: ['', [Validators.required]],
      confirmPassword: ['', [Validators.required]],
    },
    {
      validators: [PasswordMatchValidator.mustMatch],
    }
  );

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private location: Location,
    private auth: AuthService,
    private toast: ToastService
  ) {}

  ngOnInit() {
    this.auth.cognitoUser$
      .pipe(take(1))
      .subscribe((user) => (this.cognitoUser = user));

    if (!this.cognitoUser || !(this.cognitoUser.challengeName === 'NEW_PASSWORD_REQUIRED')) {
      this.router.navigate(['/login']);
    }
  }

  // Getter password
  get password(): AbstractControl | null {
    return this.newPassForm.get('password');
  }

  // Getter confirmPassword
  get confirmPassword(): AbstractControl | null {
    return this.newPassForm.get('confirmPassword');
  }

  /**
   * Método para enviar el formulario
   */
  onSubmit(): void {
    if (this.newPassForm.valid) {
      this.loading = true;
      const { password } = this.newPassForm.value;

      this.auth
        .completeNewPassword(this.cognitoUser, password)
        .then((user) => {
          this.loading = false;
          this.auth.cognitoUser$.next(user);
          this.router.navigate(['bracelets']);
        })
        .catch((err) => {
          this.loading = false;
          this.toast.error(err.message);
        });
    }
  }

  /**
   * Método para volver atrás
   */
  back(): void {
    this.location.back();
  }
}
