import { ValidationErrors } from '@angular/forms';
import { FORM_ERROR_MESSAGES } from './constants';

/**
 * Método que regresa el mensaje de error a mostrar
 * @param {ValidationErrors} errors - errores del input actual
 */
export function getControlErrorMessage(
  errors: ValidationErrors | null | undefined
): string | null | undefined {
  let errMsg: null | string = null;

  for (const key in errors) {
    if (!errors.hasOwnProperty(key)) {
      continue;
    }

    errMsg = FORM_ERROR_MESSAGES[key];
  }

  return errMsg;
}
